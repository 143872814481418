"use strict";

$(document).ready(function () {
  $(".scrollTo").on("click", function (e) {
    var offset = $('#main-head').outerHeight() - 1; // Make sure this.hash has a value before overriding default behavior

    if (this.hash !== "") {
      // Prevent default anchor click behavior
      e.preventDefault(); // Store hash

      var hash = this.hash;

      if (this.hash === "#dona-head") {
        if (window.matchMedia("(max-width: 991px)").matches) {
          hash = '#youtube';
          offset += 183;
        } else {
          hash = this.hash;
          offset -= 340;
        }
      } // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area


      $("html, body").animate({
        scrollTop: $(hash).offset().top - offset
      }, 700, function () {// Add hash (#) to URL when done scrolling (default click behavior)
        // window.location.hash = hash;
      });
    } // End if

  }); // $('#lead-form #terms_form').on('change',function(ev){
  //     var target_el = ev.currentTarget;
  //     var is_checked = $(target_el).is(':checked');
  //     if( is_checked ){
  //         $('#lead-form #terms_form').removeAttr('disabled');
  //     }
  //     else {
  //         $('#lead-form #terms_form').attr('disabled','disabled');
  //     }
  // });

  $.extend($.validator.messages, {
    required: "Questo campo è obbligatorio"
  });
  $("#lead-form").validate({
    lang: 'it'
  }); // Set the date we're counting down to

  var countDownDate = new Date("2020-12-09 18:30:00").getTime(); // Update the count down every 1 second

  var x = setInterval(function () {
    // Get today's date and time
    var now = new Date().getTime(); // Find the distance between now and the count down date

    var distance = countDownDate - now; // Time calculations for days, hours, minutes and seconds

    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
    var minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
    var seconds = Math.floor(distance % (1000 * 60) / 1000); // Display the result in the element with id="demo"

    document.getElementById("countdown").innerHTML = "&nbsp;tra " + days + " giorni " + hours + " ore e " + minutes + " minuti "; // If the count down is finished, write some text

    if (distance < 0) {
      clearInterval(x);
      document.getElementById("countdown").innerHTML = "&nbsp;ora!";
    }
  }, 1000);
});